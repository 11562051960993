export default {
  word1001: 'Chinese',
  word1002: '邮箱',
  word1003: '密码',
  word1004: '忘记密码？',
  word1005: '登录',
  word1006: '注册',
  word1007: '用户协议',
  word1008: '个人隐私',
  word1009: '关于我们',
  word1010: '账号不能为空',
  word1011: '密码不能为空',
  word1012: '该账号已被冻结',
  word1013: '账号或密码错误',
  word1014: '登录成功',
  word1015: '昵称',
  word1016: '输入右方的数字',
  word1017: '获取验证码',
  word1018: '重复',
  word1019: '数字不能为空',
  word1020: '验证码不正确',
  word1021: '两次密码不一致',
  word1022: '昵称不能为空',
  word1023: '邮箱不能为空',
  word1024: '重复密码不能为空',
  word1025: '秒后重试',
  word1026: '注册成功',
  word1027: '邮箱验证码',
  word1028: '验证码不能为空',
  word1029: '新密码',
  word1030: '修改密码',
  word1031: '新密码不能为空',
  word1032: '修改成功',
  word1033: '该账号已被禁用'
}
