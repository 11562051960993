import Vue from 'vue'
import VueI18n from 'vue-i18n' // 引入 i18n 模块
import locale from 'element-ui/lib/locale' // elementUI语言包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // elementUI中文包
import elementEnLocale from 'element-ui/lib/locale/lang/en' // elementUI英文包
import zhLocale from './zh.js' // 本地中文包
import enLocale from './en.js' // 本地英文包
import bahasaLocale from './bahasa.js' // 本地印尼语言包

Vue.use(VueI18n)

const messages = {
  'zh-CN': {
    ...zhLocale,
    ...elementZhLocale
  },
  'en-US': {
    ...enLocale,
    ...elementEnLocale
  },
  'bahasa-Indonesia': {
    ...bahasaLocale
  }
}

const language = sessionStorage.getItem('changeChange')=='yes'?(sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'zh-CN'):'en-US'

// 创建国际化实例
const i18n = new VueI18n({
  locale: language, // 默认中文
  messages
})
locale.i18n((key, value) => i18n.t(key, value))

export default i18n
