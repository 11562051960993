// 设置 cookie
const setCookie = (name, value) => {
  // 7天后重新登录
  const days = 7
  let date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + escape(value) + ';expires=' + date.toGMTString() + '; path=/'
}

export { setCookie }
